
import Image from "next/image";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCart } from "@/hooks/cart";
import { tempCartincrementQuantity, tempCartdecrementQuantity } from "@/redux/gulongph.slice";
import { addToCart, reAddToCart, removeFromCart, removeAllCart } from "@/redux/cart.slice";
import ItemName from "../Product/Shop/Items/Name";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BuildIcon from '@mui/icons-material/Build';
import useSWR from "swr";
import dayjs from "dayjs";
//const fetcher = (url) => fetch(url).then((res) => res.json());
const fetcher = async (data) => {
    const url = data[0];
    if(!data[1]) return;
    if(data[1].length <= 0) return;
    const payload = JSON.stringify({pids : data[1]});
    const options = {
        method: "POST",
        ...(payload && { body:  payload }),
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    return fetch(url, options).then(r => r.json());
};
const brandsToday = ['APOLLO', 'ARIVO', 'DOUBLECOIN', 'BFGOODRICH', 'MICHELIN', 'YOKOHAMA'];

const PriceLabel = ({ item, isPromo, mini, itemFinalPrice}) => {
    const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
    return(
        <div className={`col-span-2 flex flex-col  ${mini ? 'justify-end' : ''}`}>
            <div className={`flex align-middle ${mini ? 'justify-end flex-row space-x-2' : 'justify-start flex-col'}`}>
                {isPromo && (
                    <Fragment>
                        {!item.buy3get1 && (
                            <Fragment>
                                {parseFloat(item.quantity) >= 4 && (
                                    <NumericFormat className="mt-1 line-through whitespace-nowrap text-gray-400 font-semibold sm:tracking-wider sm:text-base text-xs" value={parseFloat(itemPrice)} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
                <NumericFormat className="whitespace-nowrap text-gray-600 font-semibold sm:tracking-wider sm:text-xl text-base" value={itemFinalPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
            </div>
            <span className={`text-sm text-gray-600 whitespace-nowrap ${mini ? 'text-right' : ''}`}>*Price per tire</span>
        </div>
    );
}

const RemoveProductDialog = ({openRemoveDialog, setOpenRemoveDialog, cartItems, item, removeProduct, title }) => {

    return(
        <Dialog
            className="rounded-full"
            sx={{
                "& .MuiDialog-container .MuiPaper-root":{
                    borderRadius: "0.5rem",
                    marginLeft: 1,
                    marginRight: 1,
                },
            }}
            open={openRemoveDialog}
            onClose={()=>setOpenRemoveDialog(!openRemoveDialog)}
        >
            <DialogTitle className="!text-center text-white font-semibold text-3xl bg-red-600 !py-3 ">
                {title}
                <IconButton
                    aria-label="close"
                    onClick={()=>setOpenRemoveDialog(!openRemoveDialog)}
                    className="text-white !border-2 !border-white !rounded-full !border-solid hover:text-black"
                    sx={{
                        position: 'absolute',
                        right: 7,
                        top: 7,
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <h1 className="text-lg text-center font-semibold text-gray-800 mt-2">
                        Are you sure you want to remove item from cart?
                    </h1>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="flex flex-row justify-center gap-2 align-middle items-center px-2">
                <button
                    type="button"
                    onClick={()=>{
                        setOpenRemoveDialog(false);
                    }}
                    className="close-cart-button"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={()=>{
                        setOpenRemoveDialog(false);
                        removeProduct(item);
                    }}
                    className="empty-cart-button"
                >
                    {cartItems?.length <= 1 ? 'Empty Cart' : 'Remove Item'}
                </button>
            </DialogActions>
        </Dialog>
    );
};

const MaxQuantityDialog = ({openMaxQuantityDialog, setOpenMaxQuantityDialog, cartItems, item, title, qty }) => {

    return(
        <Dialog
            className="rounded-full"
            sx={{
                "& .MuiDialog-container .MuiPaper-root":{
                    borderRadius: "0.5rem",
                    marginLeft: 1,
                    marginRight: 1,
                },
            }}
            open={openMaxQuantityDialog}
            onClose={()=>setOpenMaxQuantityDialog(!openMaxQuantityDialog)}
        >
            <DialogTitle className="!text-center text-white font-semibold text-3xl bg-red-600 !py-3 ">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <h1 className="text-lg text-center font-semibold text-gray-800 mt-2">
                        Only {qty} stocks available
                    </h1>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="flex flex-row justify-center gap-2 align-middle items-center px-2">
                <button
                    type="button"
                    onClick={()=>{
                        setOpenMaxQuantityDialog(false);
                    }}
                    className="w-full text-red-600 px-4 py-2 font-semibold bg-white border border-red-400 rounded hover:text-white hover:bg-red-600 transition-all"
                >
                    Close
                </button>
            </DialogActions>
        </Dialog>
    );
};
export default function Cart({ showCart = null, mini = null, removeButton = null, selectedProduct = null }){
    const router = useRouter();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const tempCart = useSelector((state) => state.gulongph.temp_cart);
    const { decrementQty, incrementQty, removeProduct, viewProduct, addToCartGtag } = useCart();
    const [cartItems, setCartItems] = useState(null);
    const [disableQuantities, setDisableQuantities] = useState(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [openMaxQuantityDialog, setOpenMaxQuantityDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const { data, error, isLoading } = useSWR([`${process.env.NEXT_PUBLIC_API_URL}/chItms`, cartItems?.map(item => { return item.id; })], fetcher);

    const showRemoveDialog = (item) =>{
        setSelectedItem(item);
        setOpenRemoveDialog(true);
    };

    useEffect(()=>{
        if(!router.isReady) return;
        if(router.asPath == "/checkout?step=location" ||
                router.asPath == "/checkout?step=contact_information" ||
                    router.asPath == "/checkout?step=payment_method") setDisableQuantities(true);
        else setDisableQuantities(false);
    },[router]);

    useEffect(()=>{
        if(selectedProduct) setCartItems(tempCart);
        else{
            if(!cart.length) setCartItems(null);
            else setCartItems(cart);
        }
    },[cart, selectedProduct, tempCart]);

    useEffect(()=>{
        if(!data) return;
        let updatedItems = [];
        cartItems.forEach(mItem => {
            let item = data.find(i => {if(i.id == mItem.id) return i});
            if(!item){
                dispatch(removeFromCart(mItem.id));
            }
            else{
                let buy3get1 = item.promo_name == "3+1" ? 1 : mItem.buy3get1;
                updatedItems.push({...item, quantity: mItem.quantity, buy_it_now: mItem.buy_it_now, buy3get1: buy3get1, date_added_to_cart: mItem.date_added_to_cart});
            }
        });
        if(updatedItems.length > 0){
            updatedItems.forEach(upItem => {
                let currentDate = dayjs().format();
                let item_last_update = dayjs(upItem.date_updated);
                let item_price_last_update = dayjs(upItem.lp_date);
                let date_added_to_cart = dayjs(upItem.date_added_to_cart);
                let promo_last_update = dayjs(upItem.promo_updated_at);
                let checkD1Diff = date_added_to_cart.diff(item_last_update); // Item last update in product table
                let checkD2Diff = date_added_to_cart.diff(item_price_last_update); // Price last update in product price
                let checkD3Diff = date_added_to_cart.diff(promo_last_update); // promo last update
                let buy3get1 = upItem.promo_name == "3+1" ? 1 : upItem.buy3get1;
                buy3get1 = upItem.promo_disabled == 0 ? 1 : 0;
                if(checkD1Diff < 0){ // For item details update
                    dispatch(addToCart({...upItem, date_added_to_cart: currentDate, buy3get1: buy3get1, re_add: 1}));
                }
                else if(checkD2Diff < 0){ // For item price update
                    dispatch(addToCart({...upItem, date_added_to_cart: currentDate, buy3get1: buy3get1, re_add: 1}));
                }
                else if(checkD3Diff < 0){ // For promo update
                    dispatch(addToCart({...upItem, date_added_to_cart: currentDate, buy3get1: buy3get1, re_add: 1}));
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    return(
        <div className="w-full">
            <RemoveProductDialog
                title={'Remove Item?'}
                cartItems={cartItems}
                item={selectedItem}
                removeProduct={removeProduct}
                openRemoveDialog={openRemoveDialog}
                setOpenRemoveDialog={setOpenRemoveDialog}
            />
            <ul className={`divide-y divide-gray-200 ${mini ? '' : 'border-b border-red-100'}`}>
                {!cartItems && (
                    <li className="flex flex-col justify-center text-center hover:bg-gray-50 py-2">
                        <h1 className="text-gray-300 text-xl font-semibold">Your shopping cart is empty</h1>
                        <ShoppingCartIcon className="text-gray-200 w-36 h-36 mx-auto" fontSize="large"/>
                    </li>
                )}
                {cartItems?.map((item, index)=>{
                    let isPromo = item.buy3get1 ? parseFloat(item.quantity) >= 4 ? true : false : false;
                    let preOrder = item.pre_order == "1" ? true : false;
                    if(item.DOT_SKU) isPromo = false;
                    else isPromo = true;
                    const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
                    let itemFinalPrice = itemPrice;
                    if(isPromo){
                        itemFinalPrice = (parseFloat(item.quantity) >= 4) ? item.buy3get1 ? itemPrice : itemPrice - parseFloat(itemPrice) * 0.03 : itemPrice;
                    }
                    return(
                      <Fragment key={index}>
                        <li
                            className="flex flex-row hover:bg-gray-50 py-2"
                        >
                            <div>
                                <div className={`relative ${mini ? 'h-24 w-24' : 'h-32 w-32'} select-none`}>
                                    <Image
                                        src={item.image_do ? item.image_do : '/assets/images/sample_tire.png'}
                                        alt={item.model}
                                        fill
                                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    />
                                    {/*(brandsToday?.includes(item.make)) && (
                                      <div className={`${(router.asPath.includes('checkout')) ? 'z-10 absolute bottom-2 md:bottom-2 md:right-2 -right-0 md:w-12 sm:w-[60px] w-[55px] md:h-8 h-8' : 'z-10 absolute bottom-2 md:bottom-4 md:right-2 -right-0 md:w-14 sm:w-[60px] w-[60px] md:h-10 h-12'}  `}>
                                          {item.product_banner && (
                                              <Image
                                                  src="/assets/images/next-day-v3.webp"
                                                  alt="promo"
                                                  fill
                                                  sizes="(max-width: 480px) 100vw, (max-width: 800px) 50vw, 33vw"
                                              />
                                          )}
                                      </div>
                                    )*/}
                                </div>
                            </div>
                            <div className="flex flex-row justify-between w-full pl-2">
                                <div className="flex flex-col justify-between pb-2">
                                    <div
                                        onClick={()=>{
                                            //router.push(`/product/${item.slug}`);
                                            viewProduct(item);
                                            if(showCart){
                                                showCart(false);
                                            }
                                        }}
                                        className="cursor-pointer leading-5 group"
                                    >
                                        <ItemName product={item}/>
                                    </div>
                                    {!mini && (
                                        <PriceLabel item={item} isPromo={isPromo} itemFinalPrice={itemFinalPrice} mini={mini}/>
                                    )}
                                    {isPromo && (
                                        <Fragment>
                                            {item.buy3get1 ? (
                                                <div className="text-sm">
                                                    <p className="text-blue-600 font-semibold">Buy 3, Get 4th Free</p>
                                                    <span className='text-white rounded-lg bg-green-500 tracking-wide text-sm px-2 pb-px'>
                                                        Save&nbsp;
                                                        <NumericFormat value={(item.sale_tag > 0) ? item.promo : item.srp} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                                    </span>
                                                </div>
                                            ) : (
                                                <Fragment>
                                                    {parseFloat(item.quantity) >= 4 && (
                                                        <div className="text-sm">
                                                            <p className="text-blue-600 font-semibold tracking-tighter">Bundle Promo</p>
                                                            <span className='text-white rounded-lg bg-green-500 tracking-wide text-sm px-2 pb-px'>
                                                                Save&nbsp;
                                                                <NumericFormat value={(parseFloat(itemPrice) * 0.03) * 4} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                                            </span>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                    {item.DOT_DESC && (
                                      <Fragment>
                                          <div className="text-md">
                                              <p className="text-black font-semibold">DOT&nbsp;{item.DOT_DESC}</p>
                                          </div>
                                      </Fragment>
                                    )}
                                    {(!mini && item.total_sold && parseFloat(item.total_sold) > 0) && (
                                        <div className="flex flex-row align-middle items-center mt-px">
                                            <div className='w-[18px] h-[18px]'>
                                                <Image
                                                    src="/assets/images/gulong_sold.webp"
                                                    width={15}
                                                    height={15}
                                                    alt="total-sold"
                                                />
                                            </div>
                                            <span className="-mt-1 font-semibold text-sm text-[#CC6E00] flex flex-row align-middle items-center">{item.total_sold} sold</span>
                                        </div>
                                    )}
                                </div>
                                <div className={`${mini ? 'w-full justify-end' : 'w-full justify-between'} pt-1 pb-1 flex ${ removeButton ? 'flex-col !justify-center align-middle' : 'flex-col'} space-y-1`}>
                                    <div className="flex flex-col">
                                        <span className={`text-xs text-gray-600 whitespace-nowrap ${mini ? 'text-right' : 'text-right'} text-red-600 font-bold`}>Select Quantity</span>
                                        <div className={`flex flex-row ${mini ? 'justify-end' : 'justify-end'} items-center `}>
                                            {!disableQuantities && (
                                                <button type="button"
                                                    className="cursor-pointer border p-1 border-gray-200 rounded-full text-gray-600 hover:text-red-600 focus:text-red-600 shadow"
                                                    onClick={() => {
                                                        if(parseFloat(item.quantity) > 0){
                                                            if(selectedProduct) dispatch(tempCartdecrementQuantity({tempCartId : item.id}));
                                                            else decrementQty(item);
                                                        }
                                                    }}
                                                >
                                                    <svg className="fill-current w-3" viewBox="0 0 448 512">
                                                        <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                                                    </svg>
                                                </button>
                                            )}
                                            <span className="select-none text-lg font-semibold text-center px-1.5" >{item.quantity}</span>
                                            {!disableQuantities && (
                                                <button type="button"
                                                    className="cursor-pointer border p-1 border-gray-200 rounded-full text-gray-600 hover:text-green-600 focus:text-green-600 shadow"
                                                    onClick={() => {
                                                        if (item.clearance_sale == 1) {
                                                            if(item.quantity == item.stock){
                                                                setOpenMaxQuantityDialog(true);
                                                            }
                                                            else {
                                                                if(selectedProduct) dispatch(tempCartincrementQuantity({tempCartId : item.id}));
                                                                else incrementQty(item);
                                                            }
                                                        }
                                                        else{
                                                            if(selectedProduct) dispatch(tempCartincrementQuantity({tempCartId : item.id}));
                                                            else incrementQty(item);
                                                        }
                                                    }}
                                                >
                                                    <svg className="fill-current w-3" viewBox="0 0 448 512">
                                                        <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {mini && (
                                        <Fragment>
                                            <PriceLabel item={item} isPromo={isPromo} itemFinalPrice={itemFinalPrice} mini={mini}/>
                                            {(item.total_sold && parseFloat(item.total_sold) > 0) && (
                                                <div className="flex flex-row align-middle items-center mt-px">
                                                    <div className='w-[18px] h-[18px]'>
                                                        <Image
                                                            src="/assets/images/gulong_sold.webp"
                                                            width={15}
                                                            height={15}
                                                            alt="total-sold"
                                                        />
                                                    </div>
                                                    <span className="-mt-1 font-semibold text-sm text-[#CC6E00] flex flex-row align-middle items-center">{item.total_sold} sold</span>
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                    {!removeButton && (
                                        <div className="flex justify-end">
                                            {!mini && (
                                                <button type="button"
                                                    onClick={() => {
                                                        removeProduct(item);
                                                    }}
                                                        className="select-none text-center font-semibold text-red-600 cursor-pointer uppercase text-sm px-1 py-px border border-red-400 rounded hover:bg-red-600 hover:text-white hover:scale-x-95 duration-200 transition ease-linear"
                                                    >
                                                    Remove
                                                </button>
                                            )}
                                            {(mini && !disableQuantities) && (
                                                <button type="button"
                                                    onClick={() => {
                                                        showRemoveDialog(item);
                                                    }}
                                                        className="select-none text-center font-semibold text-red-600 cursor-pointer uppercase text-sm px-1 py-px border border-red-400 rounded hover:bg-red-600 hover:text-white hover:scale-x-95 duration-200 transition ease-linear"
                                                    >
                                                    Remove
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                        {(brandsToday?.includes(item.make)) && (
                          <span className={`${(router.asPath.includes('checkout')) ? 'hidden' : 'inline-block'} relative text-green-600 text-base font-semibold mt-1`}>
                              &nbsp;&nbsp;(&nbsp;<BuildIcon className="text-green-600" fontSize="small"/>&nbsp;
                              Next-Day Installation!&nbsp;)
                              <span className="flex absolute h-1.5 w-1.5 top-0 left-7 md:left-8">
                                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                  <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-red-600"></span>
                              </span>
                          </span>
                        )}
                        <MaxQuantityDialog
                            title={'Available stocks'}
                            cartItems={cartItems}
                            item={selectedItem}
                            qty={item.stock}
                            openMaxQuantityDialog={openMaxQuantityDialog}
                            setOpenMaxQuantityDialog={setOpenMaxQuantityDialog}
                        />
                      </Fragment>
                    )
                })}
            </ul>

        </div>
    )
}
